.bgcolor {
  --bgcolor: #F3ECDC;
  background-color: var(--bgcolor);
}
.bgcolor--two {
  --bgcolor: #E5DEC8;
}
.bgcolor--three {
  --bgcolor: #F9F9F9;
}
.bgcolor--four {
  --bgcolor: #ffffff;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/pernova_papierstruktur.jpg);
}
.bgcolor--five {
  --bgcolor: #84A579;
  --textcolor: #ffffff;
  --titlecolor: #ffffff;
  --accentcolor: #ffffff;
  --linkcolor: #ffffff;
}
.bgcolor--six {
  --bgcolor: #665852;
  --bgcolor-hov: #544843;
}
.catcolor {
  --catcolor: #665852;
}
.catcolor--one {
  --catcolor: #69CA81;
}
.catcolor--two {
  --catcolor: #73B8E7;
}
.catcolor--three {
  --catcolor: #F57979;
}
.catcolor--four {
  --catcolor: #FFA962;
}
.catcolor--five {
  --catcolor: #B891E1;
}
.catcolor--six {
  --catcolor: #C5B646;
}
:root {
  --sitecolor: #ABABAB;
  --textcolor: #665852;
  --titlecolor: #665852;
  --accentcolor: #665852;
  --linkcolor: #665852;
  --linkcolor-hov: #ABABAB;
  --spaceValue: 8px;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  --linkcolor-hov: var(--textcolor);
  line-height: 1.45454545;
  font-family: inherit;
  cursor: pointer;
  color: var(--linkcolor);
  padding-right: 45px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.023em;
  font-weight: 600;
  position: relative;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.button:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    color: var(--linkcolor-hov);
  }
  .button:hover:after,
  .button:focus:after {
    right: 0;
    background-color: var(--linkcolor-hov);
  }
}
.layout1 h1 {
  font-size: 80px;
}
.area--one {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0 20px;
}
.area--one .unit {
  grid-column: span 6;
}
.area--one .unitOne--1-1 .unit__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0 20px;
}
.area--one .unitOne--1-1 .unit__body .part--slim {
  width: calc((100% - 20px) / 2);
}
.area--one .unitOne--1-1 .unit__body .part--tiny {
  width: calc((100% - 20px) / 2);
}
.area--one .unitOne--1-3 {
  grid-column: span 3;
}
.area--one .unitOne--1-4 {
  grid-column: span 3;
}
.area--one .unitThree {
  grid-column: span 3;
}
@media (max-width: 1023px) {
  .area--one .unitThree {
    padding-right: calc(var(--spacePart) * 2);
    box-sizing: border-box;
  }
}
.area--one .unitFour {
  grid-column: span 3;
}
.area--one .unitFour.unitFour--variantTwo {
  grid-column: span 6;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 20px;
}
.area--one .unitTwo {
  min-height: calc(var(--js-elementHeight, 400px) + (var(--spacePart) * 2));
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight {
  box-sizing: border-box;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.pict.part--wide:last-child {
  position: absolute;
  top: 0;
  right: 0;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft {
  box-sizing: border-box;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.pict.part--wide:last-child {
  position: absolute;
  top: 0;
  left: 0;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight {
  padding-right: 42.13483146%;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.pict.part--wide:last-child {
  width: 39.3258427%;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft {
  padding-left: 42.13483146%;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.pict.part--wide:last-child {
  width: 39.3258427%;
}
/*# sourceMappingURL=./screen-medium.css.map */